/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
// import ReactGA from 'react-ga';
import { Typography, Form, Radio, Checkbox, Input, Space, Row, Col, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import config from '../../config';

// import { getResources } from '../../redux/dataSlice';
import { Header, BorderButton } from '../common';
import IndicationLine from '../common/IndicationLine';

import styles from '../../Styles/Survey.module.scss';
import '../../Styles/Survey.scss';

const { TextArea } = Input;
const { Title } = Typography;

const surveyName = 'XCOPRI Survey Jan 2024';

const surveyQuestions = {
  q1: {
    text: '1. This program has increased my comfort level with prescribing XCOPRI to my patients.'
  },
  q2: {
    text: '2. Which patients would you now consider prescribing XCOPRI for that you didn’t consider before the program? <span style="color: red;">Select all that apply.</span>'
  },
  q3: {
    text: '3. What was the most impactful information in helping to improve your perception of XCOPRI? <span style="color: red;">Select up to 3.</span>'
  },
  q4: {
    text: '4. Following this presentation, do you agree that your perception of XCOPRI has changed regarding the below topics?',
    subQuestions: [
      '<span style="display: none">placeholder</span>',
      'Efficacy – seizure freedom',
      'Efficacy – seizure reduction',
      'Safety as an ASM',
      'Use in broad patient populations',
      'Use as adjunctive therapy',
      'Dosing and titration',
      'Patient adherence',
      'Accessibility at pharmacy',
      'Identifying appropriate patients in my practice'
    ],
    subQuestionAnswers: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree']
  },
  q5: {
    text: '5. Please assign unique rankings from 1 to 7, with 1 being the most interested, to the following areas in terms of your interest in learning more. <br/><span style="color: red;">(Select at least 3 responses).</span>',
    // text: '5. Please rank the following areas from 1-8 in terms of your interest in learning more <span style="color: red;">(1 being the most interested).</span>',
    subQuestions: [
      '<span style="display: none">placeholder</span>',
      'Managing adjunctive ASMs',
      'Long-term safety',
      'Long-term efficacy',
      'Dosing and titration',
      'Use in broad patient types',
      'Earlier use in treatment algorithm',
      'Access/co-pay'
      // 'Other (please explain)'
    ],
    subQuestionAnswers: ['1', '2', '3', '4', '5', '6', '7']
  }
};

const Survey = ({ headers }) => {
  const surveyRef = useRef(null);

  const [form] = Form.useForm();

  const [modalOpen, setModalOpen] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [q5Values, setQ5Values] = useState({
    q5_1: null,
    q5_2: null,
    q5_3: null,
    q5_4: null,
    q5_5: null,
    q5_6: null,
    q5_7: null,
    q5_8: null
  });
  const [q5Error, setQ5Error] = useState(null);

  // const [q5resetTime, setQ5resetTime] = useState(null);

  const [q2TextFieldEnabled, setQ2TextFieldEnabled] = useState(false);
  const [q3TextFieldEnabled, setQ3TextFieldEnabled] = useState(false);
  // const [q5TextFieldEnabled, setQ5TextFieldEnabled] = useState(false);

  const [meetingId, setMeetingId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line import/no-named-as-default-member
    const meeting_id = config.getUrlParams('meeting_id');

    // if (meeting_id) {
    config
      .fetch(`${config.baseApiUrl}/api/survey?surveyname=${surveyName}`, {
        method: 'GET',
        headers: headers
      })
      .then((res) => {
        // console.log('res', res);
        if (res.status === 200) {
          setLoaded(true);
          if (meeting_id) {
            setMeetingId(meeting_id);
          }
        } else {
          setLoaded(true);
          setAlreadySubmitted(true);
          toggleModal();
        }
      })
      .catch((err) => {
        console.log('err', err);
      });

    if (surveyRef.current) {
      surveyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateQ5Values = (groupKey) => {
    return (evt) => {
      setQ5Values({ ...q5Values, [groupKey]: evt.target.checked ? evt.target.value : undefined });
    };
  };

  // const clearQ5Values = (groupKey) => {
  //   return () => {
  //     console.log('clearQ5Values', groupKey);
  //     setQ5Values({ ...q5Values, [groupKey]: undefined });
  //   };
  // };

  const onSurveySubmit = (values) => {
    if (submitting) {
      return;
    }

    setQ5Error(null);

    console.log('Success:', values);

    // we need to validate Q5 to make sure there are at least 3 answers
    // we also have to make sure that the answers are unique for q5

    // const q5Answers = Object.keys(q5Values)
    //   .filter((key) => values[key])

    const q5Answers = _.omitBy(q5Values, _.isNil);
    const q5AnswerCount = _.keys(q5Answers).length;
    // console.log('q5Answers', q5Answers);

    if (
      q5AnswerCount < 3 ||
      (q5AnswerCount >= 3 && _.uniq(_.values(q5Answers)).length !== _.values(q5Answers).length)
    ) {
      console.log('q5Answers', q5Answers);
      // check if we have at least 3 answers
      if (q5AnswerCount < 3) {
        setQ5Error('This section is required. Please select at least 3 responses.');
      } else if (_.uniq(q5Answers).length !== q5Answers.length) {
        setQ5Error('Please select unique answers.');
      }

      // scroll to id q5Text
      const q5Text = document.getElementById('q5Text');
      if (q5Text) {
        q5Text.scrollIntoView({ behavior: 'smooth' });
      }

      return;
    }

    const data = {
      surveyname: surveyName,
      responseguid: Date.now(),
      meeting_id: meetingId,
      responses: []
    };

    _.keys(values).forEach((key) => {
      const questionKey = key.split('_')[0];
      const subQuestionKey = key.split('_')[1];

      if (questionKey === 'q5') {
        return;
      }

      // continue if subQuestionKey contains the word Other as we already processed it in the previous question
      if (subQuestionKey && subQuestionKey?.includes('Other')) {
        return;
      }

      let questionText = surveyQuestions[questionKey].text.split('<span')[0].trim();
      let subQuestionText = '';

      if (subQuestionKey) {
        subQuestionText = surveyQuestions[questionKey].subQuestions[subQuestionKey];
        questionText = `${questionText} - ${subQuestionText}`;
      }

      const answerList = _.isArray(values[key]) ? values[key] : [values[key]];
      answerList.forEach((answer) => {
        // if answer is empty we skip it
        if (!answer) {
          return;
        }
        // if the answer cointains the word Other we need to get the value from the other fields
        let answerText = answer;
        if (
          (answer.includes('Other') || (subQuestionText && subQuestionText.includes('Other'))) &&
          values[`${questionKey}_Other`]
        ) {
          const otherAnswer = values[`${questionKey}_Other`];

          if (otherAnswer) {
            answerText = `${answer}: ${otherAnswer}`;
          }
        }
        data.responses.push({
          questionKey,
          subQuestionKey,
          surveyquestion: questionText,
          surveyanswer: answerText
        });
      });
    });

    // add q5 answers
    _.keys(q5Answers).forEach((key) => {
      const subQuestionKey = key.split('_')[1];
      const questionText = surveyQuestions.q5.text.split('<span')[0].trim();
      const subQuestionText = surveyQuestions.q5.subQuestions[subQuestionKey];

      data.responses.push({
        questionKey: 'q5',
        subQuestionKey,
        surveyquestion: `${questionText} - ${subQuestionText}`,
        surveyanswer: q5Answers[key]
      });
    });

    setSubmitting(true);
    config
      .fetch(`${config.baseApiUrl}/api/survey`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      })
      .then((res) => {
        console.log('res', res);
        if (res.status === 200) {
          toggleModal();
          setError(false);
        } else if (res.status === 403) {
          setLoaded(true);
          setAlreadySubmitted(true);
          toggleModal();
        } else {
          setError(true);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log('err', err);
        setError(true);
        setSubmitting(false);
      });
  };

  const onValuesChange = (changedValues, allValues) => {
    console.log('onValuesChange', changedValues, 'xxxx', allValues);
    // for q3 we allow to select up to 3 options
    if (changedValues.q3 && changedValues.q3.length > 3) {
      // remove the last selected option
      const lastSelected = changedValues.q3[changedValues.q3.length - 1];
      const newQ3 = changedValues.q3.filter((item) => item !== lastSelected);
      // console.log('newQ3', newQ3);
      // set the new value
      // allValues.q3 = newQ3;
      form.setFieldsValue({ q3: newQ3 });
    }

    // for q2 if other is selected we enable the text field
    if (changedValues.q2 && changedValues.q2.includes('Other (please explain)')) {
      setQ2TextFieldEnabled(true);
    } else if (changedValues.q2) {
      setQ2TextFieldEnabled(false);
      // reset the fields value
      form.setFieldsValue({ q2_Other: '' });
    }

    // for q3 if other is selected we enable the text field
    if (changedValues.q3 && changedValues.q3.includes('Other (please explain)')) {
      setQ3TextFieldEnabled(true);
    } else if (changedValues.q3) {
      setQ3TextFieldEnabled(false);
      // reset the fields value
      form.setFieldsValue({ q3_Other: '' });
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const closeModal = () => {
    setModalOpen(false);
    setAlreadySubmitted(false);
    setLoaded(false);
    form.resetFields();
    history.push('/');
  };

  const renderModal = () => {
    let modalText = 'Thank you for your feedback.';
    if (alreadySubmitted) {
      modalText = 'You have already submitted this survey.';
    }

    return (
      <Modal
        key={'surveyModal'}
        visible={modalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        destroyOnClose
        footer={null}
        centered
        className="survey-modal"
      >
        <div className="flex flex-column horizontal-center vertical-center" style={{ textAlign: 'center' }}>
          <Title level={4} style={{ fontSize: 26 }}>
            {modalText}
          </Title>
          <br />
          <BorderButton wrapperStyle={{ marginTop: 10, textAlign: 'center' }} onClick={closeModal}>
            Close
          </BorderButton>
        </div>
      </Modal>
    );
  };

  const getRowClass = (idx, isOdd) => {
    if (idx === 0) {
      return 'labelRow';
    }
    return isOdd ? 'oddRow' : 'evenRow';
  };

  const getRadioLabel = (idx, radioLabel) => {
    if (idx === 0) {
      // const newLabel = `${radioLabel} <br/> (Most Interested)`;
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: `${radioLabel} <span class="subLabel">(Most Interested)</span>`
          }}
        />
      );
    }
    if (idx === surveyQuestions.q5.subQuestionAnswers.length - 1) {
      // const newLabel = `${radioLabel} <br/> (Least Interested)`;
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: `${radioLabel} <span class="subLabel">(Least Interested)</span>`
          }}
        />
      );
    }
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `${radioLabel} <br/> <span class="subLabel" style="visibility: hidden;">(Mid)</span>`
        }}
      />
    );
  };

  const getRadioClass = (idx, questionId) => {
    if (idx === 0) {
      return 'first';
    }
    if (idx === surveyQuestions[questionId].subQuestionAnswers.length - 1) {
      return 'last';
    }
    return 'mid';
  };

  if (!loaded) {
    return <Spin size="large" indicator={<LoadingOutlined style={{ color: '#624B78' }} spinning />} />;
  }

  return (
    <div className={`flex flex-column ${styles.survey}`} ref={surveyRef}>
      {renderModal()}
      <Header
        styles={styles}
        title={() => (
          <>
            <Title className="text__white" level={2}>
              Post Program Survey
            </Title>
            <Title className="text__white" level={3} style={{ marginTop: 0, fontWeight: 400 }}>
              Thank you for attending an XCOPRI speaker program. We would appreciate your feedback on the event and
              kindly request that you take a few minutes to complete the 5-question survey below.
            </Title>
            <IndicationLine />
          </>
        )}
      />
      <div className={styles['survey-content']}>
        <Form name="survey" onValuesChange={onValuesChange} onFinish={onSurveySubmit} form={form}>
          <h4 className={styles['question-text']} dangerouslySetInnerHTML={{ __html: surveyQuestions.q1.text }} />

          <Form.Item name="q1" style={{ marginLeft: 20 }}>
            <Radio.Group>
              <div className={styles['row-to-column']}>
                {['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'].map((item, idx) => {
                  return (
                    <Radio key={idx} value={item}>
                      {item}
                    </Radio>
                  );
                })}
              </div>
            </Radio.Group>
          </Form.Item>
          <div className={styles['survey-content-separator']} />
          <h4 className={styles['question-text']} dangerouslySetInnerHTML={{ __html: surveyQuestions.q2.text }} />
          <Form.Item name="q2" style={{ marginLeft: 20 }}>
            <Checkbox.Group>
              <Space direction="vertical">
                {[
                  'Use as first adjunct',
                  'Use as second adjunct',
                  'Transition to monotherapy',
                  'Monotherapy',
                  'Other (please explain)'
                ].map((item, idx) => {
                  return (
                    <Checkbox key={idx} value={item}>
                      {item}
                    </Checkbox>
                  );
                })}
              </Space>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="q2_Other" className={`${styles.otherFieldWrapper} ${styles.otherFieldInputWrapper}`}>
            <Input disabled={!q2TextFieldEnabled} placeholder="Enter your response" maxLength={400} />
          </Form.Item>
          <Form.Item name="q2_Other" className={`${styles.otherFieldWrapper} ${styles.otherFieldTextWrapper}`}>
            <TextArea rows={4} disabled={!q2TextFieldEnabled} placeholder="Enter your response" maxLength={400} />
          </Form.Item>

          <div className={styles['survey-content-separator']} />
          <h4 className={styles['question-text']} dangerouslySetInnerHTML={{ __html: surveyQuestions.q3.text }} />
          <Form.Item name="q3" style={{ marginLeft: 20 }}>
            <Checkbox.Group>
              <Space direction="vertical">
                {[
                  'Median percentage reduction in seizure frequency per 28 days and the percentage of patients who achieved seizure reduction of 100%',
                  'Patients achieving zero seizures within first 4 weeks',
                  'Patients achieving zero seizures for ≥3, ≥6, and ≥12 months',
                  'Safety and tolerability information',
                  'Guidance on managing concomitant medications',
                  'Dosing, titration, and how XCOPRI is supplied',
                  'Presentation/discussion of patient case(s)',
                  'Mechanism of action',
                  'Access/cost',
                  'Other (please explain)'
                ].map((item, idx) => {
                  return (
                    <Checkbox key={idx} value={item}>
                      {item}
                    </Checkbox>
                  );
                })}
              </Space>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="q3_Other" className={`${styles.otherFieldWrapper} ${styles.otherFieldInputWrapper}`}>
            <Input disabled={!q3TextFieldEnabled} placeholder="Enter your response" maxLength={400} />
          </Form.Item>
          <Form.Item name="q3_Other" className={`${styles.otherFieldWrapper} ${styles.otherFieldTextWrapper}`}>
            <TextArea rows={4} disabled={!q3TextFieldEnabled} placeholder="Enter your response" maxLength={400} />
          </Form.Item>

          <div className={styles['survey-content-separator']} />
          <h4 className={styles['question-text']} dangerouslySetInnerHTML={{ __html: surveyQuestions.q4.text }} />
          {surveyQuestions.q4.subQuestions.map((item, idx) => {
            const isOdd = idx % 2 === 0;

            return (
              <Form.Item
                key={idx}
                name={`q4_${idx}`}
                className={`${idx === 0 ? 'multiAnswerLabel' : 'multiAnswer'} ${styles.answerRow} ${
                  styles[getRowClass(idx, isOdd)]
                } q4`}
              >
                <Row align="middle">
                  <Col xs={24} lg={9}>
                    <span className={`${styles.rowQuestion}`} dangerouslySetInnerHTML={{ __html: item }} />
                  </Col>
                  <Col key={idx} xs={24} lg={14}>
                    <Radio.Group
                      className={styles['radio-group']}
                      // style={{ border: '1px solid green' }}
                    >
                      {surveyQuestions.q4.subQuestionAnswers.map((subItem, subIdx) => {
                        return (
                          <Radio
                            value={subItem}
                            key={subIdx}
                            className={` ${styles['radio-button']} ${idx === 0 ? 'hideRadio' : ''} ${
                              subIdx > 0 && subIdx < surveyQuestions.q4.subQuestionAnswers.length - 1
                                ? 'centerLabel'
                                : ''
                            } ${getRadioClass(subIdx, 'q4')}`}
                          >
                            {subItem}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </Col>
                </Row>
              </Form.Item>
            );
          })}
          <div className={styles['survey-content-separator']} />
          <div className={styles['survey-content-separator']} />
          <h4
            id="q5Text"
            className={styles['question-text']}
            dangerouslySetInnerHTML={{ __html: surveyQuestions.q5.text }}
          />

          {q5Error && (
            <p
              style={{
                color: 'red',
                fontSize: 22,
                marginLeft: 15,
                fontWeight: 'bold',
                textDecoration: 'underline',
                textAlign: 'center'
              }}
            >
              {q5Error}
            </p>
          )}

          {surveyQuestions.q5.subQuestions.map((item, idx) => {
            const isOdd = idx % 2 === 0;

            return (
              <>
                <Form.Item
                  key={idx}
                  name={`q5_${idx}`}
                  className={`${idx === 0 ? 'multiAnswerLabel' : 'multiAnswer'} ${styles.answerRow} ${
                    styles[getRowClass(idx, isOdd)]
                  } q5`}
                >
                  <Row align="middle">
                    <Col xs={24} lg={9}>
                      <span className={`${styles.rowQuestion}`} dangerouslySetInnerHTML={{ __html: item }} />
                    </Col>
                    <Col key={idx} xs={24} lg={14}>
                      <Radio.Group
                        className={styles['radio-group']}
                        onChange={updateQ5Values(`q5_${idx}`)}
                        value={q5Values[`q5_${idx}`]}
                      >
                        {surveyQuestions.q5.subQuestionAnswers.map((subItem, subIdx) => {
                          return (
                            <Radio
                              value={subItem}
                              name={subItem}
                              key={subIdx}
                              // style={{ border: '1px solid red', maxWidth: 75 }}
                              className={`${styles['radio-button']} ${
                                idx === 0 ? 'hideRadio' : ''
                              } q5-radio ${getRadioClass(subIdx, 'q5')}`}
                            >
                              {getRadioLabel(subIdx, subItem)}
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                    </Col>
                    {/* {idx !== 0 && (
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={clearQ5Values(`q5_${idx}`)}
                        className={`bold purple ${styles.clearButton}`}
                      >
                        clear
                      </span>
                    )} */}
                  </Row>
                </Form.Item>
                {/* {idx === surveyQuestions.q5.subQuestions.length - 1 && (
                  <div className={`${styles.oddRow} ${styles.q5otherFieldWrapper}`}>
                    <Form.Item name="q5_Other" className={styles.q5otherFieldInputWrapper}>
                      <Input
                        disabled={!q5TextFieldEnabled}
                        placeholder="Enter your response"
                        maxLength={400}
                        name="q5_Other"
                        style={{ width: '95%' }}
                      />
                    </Form.Item>
                  </div>
                )} */}
              </>
            );
          })}

          {/* <div className={styles['survey-content-separator']} /> */}
          {error && (
            <p style={{ color: 'red', textAlign: 'center' }}>Something went wrong... please try again later!</p>
          )}
          <div className={styles['submit-button-wrapper']}>
            {submitting ? (
              <Spin size="large" indicator={<LoadingOutlined style={{ color: '#624B78' }} spinning />} />
            ) : (
              <BorderButton htmlType="submit">Submit</BorderButton>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { headers } = auth;
  return { headers };
};
export default connect(mapStateToProps, null)(Survey);
